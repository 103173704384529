$header_color: #fff;
$body_color: #f5f5f5;
$navbar_color: #346abb;
$navbar_hover: #25E4B9;
$card_background_color: #191919;

@mixin transition($property, $duration, $type) {
    -webkit-transition: $property $duration $type;
    -moz-transition: $property $duration $type;
    -o-transition: $property $duration $type;
}

@mixin width_height($width, $height) {
    width: $width;
    height: $height;
}


* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Titillium Web', sans-serif;
}



body {
    //For Footer Configuration
    position: relative;
    min-height: 100vh;

    h2,
    h3,
    h4,
    h5,
    h6 {
        font-weight: bold;
    }
}

#root {
    background-color: $body_color;
    line-height: 2rem;
}

//FOR DARK MODE SASS-SCSS CODE
#dark {
    #navbar {
        background-color: $card_background_color;

        //LIGHT and DARK Mode Configuration
        .switch {

            .react-switch-bg {
                background: $navbar_hover !important;
            }

            span {
                margin-right: 1rem;
                color: $header_color;
            }
        }

        .navbarContainer {
            .nav {
                .navlink {
                    color: $header_color !important;
                }
            }

            //Hamburger Menu Icon Configurations
            .navbar-toggler-icon {
                background-image: none !important;
                background-color: var(--bs-white);
                height: 3px;
                width: 25px;
                margin: 10px 0;
                position: relative;
                @include transition(all, 0.35s, ease-out);
                transform-origin: center;
            }

            .navbar-toggler-icon::before {
                display: block;
                background-color: var(--bs-white);
                height: 3px;
                content: "";
                position: relative;
                top: -7px;
                @include transition(all, 0.15s, ease-out);
                /*taken down to hide quicker*/
                transform-origin: center;
            }

            .navbar-toggler-icon::after {
                display: block;
                background-color: var(--bs-white);
                height: 3px;
                content: "";
                position: relative;
                top: 4px;
                @include transition(all, 0.35s, ease-out);
                transform-origin: center;
            }

            .navbar-dark .navbar-toggler-icon,
            .navbar-dark .navbar-toggler-icon::before,
            .navbar-dark .navbar-toggler-icon::after {
                background-color: var(--bs-gray-100);
            }

            .navbar-toggler:not(.collapsed) .navbar-toggler-icon {
                transform: rotate(45deg);
            }

            .navbar-toggler:not(.collapsed) .navbar-toggler-icon::before {
                opacity: 0;
            }

            .navbar-toggler:not(.collapsed) .navbar-toggler-icon::after {
                transform: rotate(-90deg) translateX(7px);
            }

        }

    }

    .layoutContainer {
        background-color: #000;

        .card {
            box-shadow: 0px 0px 12px $header_color;
            border-radius: 8px;
            background-color: $card_background_color;

            .card-body {
                color: $header_color;
            }
        }

        .content {
            background-color: #000;

            .content-picture {
                box-shadow: 0px 0px 12px $header_color;
                border-radius: 8px;
            }

            .list-group-item {
                border: 1px solid $header_color;
                background-color: $card_background_color !important;
                color: $header_color
            }

            .social-list-group {
                .social-list-item i {
                    color: $header_color !important;
                }

                i:hover {
                    @include transition(all, 0.3s, ease-in-out);
                    color: $navbar_hover !important;
                }
            }

            #sectionDetail {

                //INDEX SECTION
                #hello {
                    #hello-title {
                        margin-top: 2rem;
                    }

                    #hello-content {
                        margin-top: 5rem;
                        line-height: 2rem;
                    }
                }

                #about {
                    .col-competencies {
                        box-shadow: 0px 0px 12px $header_color;
                        border-radius: 8px;
                    }
                }

                .contact-form {

                    input,
                    textarea,
                    button {
                        background-color: $card_background_color;
                        color: $header_color !important;

                    }
                }
            }

        }
    }

    .footer {
        background-color: $card_background_color;
    }
}

.layoutContainer {
    padding: 8rem 0;

    .card-header {
        background-color: $header_color !important;
    }

    //NAVBAR SECTION
    #navbar {
        background-color: $header_color;
        padding: 0;
        display: flex;
        align-items: center;

        .switch {
            margin-right: 2rem;
            display: flex;
            justify-content: center;
            align-items: center;

            span {
                margin-right: 1rem;
                color: #555;
            }

            .react-switch-bg {
                background: #555 !important;
            }
        }

        .navbarContainer {
            display: flex;
            justify-content: right;

            .navbar-toggler {
                border: none;
                padding: .5rem;
                box-shadow: none;
            }

            .navbar-toggler-icon {
                background-image: none !important;
                background-color: var(--bs-gray-600);
                height: 3px;
                width: 25px;
                margin: 10px 0;
                position: relative;
                @include transition(all, 0.35s, ease-out);
                transform-origin: center;
            }

            .navbar-toggler-icon::before {
                display: block;
                background-color: var(--bs-gray-600);
                height: 3px;
                content: "";
                position: relative;
                top: -7px;
                @include transition(all, 0.15s, ease-out);
                /*taken down to hide quicker*/
                transform-origin: center;
            }

            .navbar-toggler-icon::after {
                display: block;
                background-color: var(--bs-gray-600);
                height: 3px;
                content: "";
                position: relative;
                top: 4px;
                @include transition(all, 0.35s, ease-out);
                transform-origin: center;
            }

            .navbar-dark .navbar-toggler-icon,
            .navbar-dark .navbar-toggler-icon::before,
            .navbar-dark .navbar-toggler-icon::after {
                background-color: var(--bs-gray-100);
            }

            .navbar-toggler:not(.collapsed) .navbar-toggler-icon {
                transform: rotate(45deg);
            }

            .navbar-toggler:not(.collapsed) .navbar-toggler-icon::before {
                opacity: 0;
            }

            .navbar-toggler:not(.collapsed) .navbar-toggler-icon::after {
                transform: rotate(-90deg) translateX(7px);
            }

            #nav {
                display: flex;
                flex-grow: 1;

                .navlink.active {
                    background-color: $navbar_color !important;
                    color: $header_color;
                }

                .navlink {
                    font-weight: bold;
                    text-decoration: none;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    @include width_height(25%, 100%);
                    padding: .50rem;
                    color: #555;
                }

                .navlink:hover {
                    background-color: $navbar_hover;
                    @include transition(all, 0.5s, ease-in-out)
                }

                .navlink.active:hover {
                    background-color: $navbar_hover;
                }
            }
        }
    }

    //CONTENT SECTION
    .content {
        background-color: $body_color;
        min-height: calc(100vh - 249.56px);

        .contentContainer {

            //ASIDE SECTION
            #asideDetail {

                //INFORMATION SECTION
                .information-contact {
                    text-decoration: none;
                }

                .information-contact:hover {
                    @include transition(all, 0.3s, ease-in-out);
                    color: $navbar_hover;
                    text-decoration: underline;
                }

                //SOCIAL MEDIA SECTION
                .social-list-group {
                    display: flex;
                    justify-content: space-evenly;

                    .social-list-item {
                        i {
                            color: black;
                        }

                        i:hover {
                            @include transition(all, 0.3s, ease-in-out);
                            color: $navbar_hover;
                        }
                    }
                }
            }

            //SECTION SIDE
            #sectionDetail {

                //INDEX SECTION
                #hello {
                    #hello-title {
                        margin-top: 2rem;
                    }

                    #hello-content {
                        margin-top: 5rem;
                        line-height: 2rem;
                    }
                }

                //ABOUT SECTION
                .body-about {
                    a:hover {
                        @include transition(all, 0.2s, ease-in-out);
                        color: $navbar_hover;
                    }

                }

                // CORE COMPETENCIES
                .col-competencies {
                    padding: 1.5rem;
                    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.16);
                    min-height: 5rem;

                    .competencies-icon {
                        border-bottom: 1px solid rgba(0, 0, 0, .2);

                        i {
                            @include width_height(80px, 80px);
                            display: inline-block;
                            line-height: 78px;
                            border-radius: 50%;
                            font-size: 26px;
                            border: 1px solid rgba(0, 0, 0, .2);
                            margin-bottom: 15px;
                            color: $header_color;
                            background-color: $navbar_color;
                        }
                    }



                    .competencies-info {
                        padding: .5rem;
                    }

                }

                // EDUCATION & TRAININGS / PROFESSIONAL EXPERIENCE
                .education-date,
                .experience-date,
                .trainings-date {
                    font-size: .9rem;
                }

                .education-row,
                .training-row,
                .experience-row {
                    display: flex;
                    justify-content: space-between;

                    a {
                        text-decoration: none;
                        color: $navbar_color;
                    }

                    .organisation-link:hover {
                        @include transition(all, 0.2s, ease-in-out);
                        color: $navbar_hover;
                    }

                    .logo-image {
                        @include width_height(5.5rem, auto);
                        float: right;
                    }

                    .experience-list-item {
                        .fa-cog {
                            margin-right: 1rem;
                        }
                    }
                }

                .experience-list-item {
                    border: none;
                }

                .organization-text {
                    margin-top: 3rem;
                }

                .body-about,
                .body-competencies,
                .body-education,
                .body-experience,
                .body-contact {
                    padding: .2rem;
                }

                //CONTACT SECTION
                #submitBtn {
                    color: #555;
                    border: 1px solid #555;
                    @include transition(all, 0.5s, ease-in-out);
                }

                #submitBtn:hover {
                    background-color: $navbar_hover;
                    color: $body_color;
                }
            }
        }
    }
}

//FOOTER SECTION
.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 1rem;
    background-color: $navbar_color;
    color: $header_color;
}

/*RESPONSIVE - BREAKPOINTS*/
@media screen and (max-width: 767px) {

    #navbar {
        .navbarContainer {
            display: flex;
            justify-content: space-between !important;

            #nav {

                flex-direction: row;
                align-items: center;

                .navlink {
                    width: 100% !important;
                }
            }
        }
    }

    #sectionDetail {
        margin-top: 2rem;
    }
}

@media (min-width: 768px) and (max-width: 991px) {

    .layoutContainer {
        #navbar {
            .navbarContainer {
                display: flex;
                justify-content: space-between;

                #nav {

                    flex-direction: row;
                    align-items: center;

                    .navlink {
                        height: 4rem !important;
                        text-align: center;
                    }
                }
            }
        }

        #sectionDetail {
            margin-top: 2rem;
        }
    }
}

@media screen and (min-width: 992px) {
    .navlink {
        height: 4rem !important;
    }
}